import React from 'react';
import Img, { FluidObject } from 'gatsby-image';

interface Props {
  imgSrc: {
    childImageSharp: {
      fluid: FluidObject;
    };
    extension: string;
    publicURL: string;
  };
  alt: string;
}

export default ({ imgSrc, alt }: Props) => {
  const fluid = imgSrc?.childImageSharp?.fluid || imgSrc?.publicURL;
  return typeof fluid === 'string' ? <img src={imgSrc.publicURL} alt={alt} /> : <Img fluid={fluid} alt={alt} />;
};
