import Footer from '../components/Footer/Footer'
import getFluidOrSvg from '../components/utils/getFluidOrSvg'
import DaiseeD from '../components/LogoCircle'
import D from '../assets/d_daisee.svg'
import Axios from 'axios'
import * as Yup from 'yup'
import Container from '../components/Container'

import { SEO } from '../SEO'
import { PageProps, Link, graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { flatten, uniq, debounce, isEmpty } from 'lodash'
import { Oval } from 'svg-loaders-react'
import { ChevronUp, XCircle, CheckCircle, Search, Clock } from 'react-feather'
import { CSSTransition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'
import { Formik } from 'formik'
import { lighten } from 'polished'
import { InputGroup, StyledField, StyledErrorMessage, Label, StyledForm, encode } from '../components/Form'
import { Button } from '../components/Button'
import { Site } from '../components/Site'
import { AlertContext, AlertType } from '../context/alertContext'
import { Main } from '../components/Styled'

import React, { useEffect, useState, useContext, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'

interface Data {
  node: {
    frontmatter: {
      id: string
      title: string
      subtitle: string
      slug: string
      author: string
      date: string
      tags: string
      featureImg: {
        childImageSharp: {
          fluid: FluidObject
        }
        extension: string
        publicURL: string
      }
      authorImg: {
        childImageSharp: {
          fluid: FluidObject
        }
        extension: string
        publicURL: string
      }
    }
    fields: {
      readingTime: {
        text: string
      }
    }
  }
}

interface Props {
  data: {
    allMdx: {
      edges: Data[]
    }
  }
}

interface FilterParams {
  categories: string[]
  authors: string[]
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
})

const BlogPage: React.FC<PageProps & Props> = props => {
  const { allMdx } = props.data
  const { node } = allMdx.edges[0]

  const { setAlertMessage } = useContext(AlertContext)
  const themeContext = useContext(ThemeContext)
  const [isSortOpen, setIsSortOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredPosts, setFilteredPosts] = useState([])
  const [direction, setDirection] = useState<boolean>(false)
  const [filterParams, setFilterParms] = useState<FilterParams>({
    categories: [],
    authors: []
  })

  const ref = useRef<HTMLDivElement>(null)

  const [offset, setOffset] = useState<number>(0)

  useEffect(() => {
    if (ref.current) {
      setOffset(ref.current.getBoundingClientRect().height)
    }
  }, [])

  const handleResize = () => {
    if (ref.current) {
      setOffset(ref.current.getBoundingClientRect().height)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 300))

    return () => window.removeEventListener('resize', debounce(handleResize, 300))
  })

  const handleFilterToggle = (e: React.MouseEvent, key: 'categories' | 'authors') => {
    const element = e.currentTarget as HTMLDivElement
    if (filterParams[key].includes(element.innerText)) {
      const newList = filterParams[key].filter(el => el !== element.innerText)
      setFilterParms({
        ...filterParams,
        [key]: newList
      })
    } else {
      setFilterParms({
        ...filterParams,
        [key]: [...filterParams[key], element.innerText]
      })
    }
  }

  const categories = uniq(flatten(allMdx.edges.map(el => el.node.frontmatter.tags)))

  const authors = uniq(flatten(allMdx.edges.map(el => el.node.frontmatter.author)))

  useEffect(() => {
    const getfilteredPosts: any = allMdx.edges.filter(el => {
      return (
        filterParams.categories.includes(el.node.frontmatter.tags) ||
        filterParams.authors.includes(el.node.frontmatter.author)
      )
    })

    setFilteredPosts(getfilteredPosts)
  }, [filterParams])

  useEffect(() => {
    const posts: any = allMdx.edges
      .slice()
      .filter((el: any) => el.node.frontmatter.title.toLowerCase().includes(searchValue.toLowerCase()))
    setFilteredPosts(posts)
  }, [searchValue])

  return (
    <Site seo={SEO.blog} {...props}>
      <Main>
        <BlogWrapper>
          <BG>
            <Container>
              <WindowInner>
                <Link to={`/blog/${node.frontmatter.slug}/`}>
                  <WindowImgContainer>
                    {getFluidOrSvg({ imgSrc: node.frontmatter.featureImg, alt: '' })}
                  </WindowImgContainer>
                </Link>
                <Link to={`/blog/${node.frontmatter.slug}/`}>
                  <DescriptionWrapper>
                    <Category>{node.frontmatter.tags}</Category>
                    <DescriptionHeading>{node.frontmatter.title}</DescriptionHeading>
                    <DescriptionParagraph>{node.frontmatter.subtitle}</DescriptionParagraph>
                    <AuthorReadingWrapper>
                      <DescriptionAuthor>By {node.frontmatter.author}</DescriptionAuthor>
                      <span>·</span>
                      <ReadingTime>
                        <Clock size={16} color='#fff' />
                        <StyledDate>{node.fields.readingTime.text}</StyledDate>
                      </ReadingTime>
                    </AuthorReadingWrapper>
                    <AuthorImg>
                      {getFluidOrSvg({
                        imgSrc: node?.frontmatter?.authorImg ?? { publicURL: D },
                        alt: node.frontmatter.author
                      })}
                    </AuthorImg>
                  </DescriptionWrapper>
                </Link>
              </WindowInner>
            </Container>
          </BG>
          <BlogContainer>
            <BlogNavigation>
              <BlogHeading>Featured Posts</BlogHeading>
              <SearchWrapper>
                <Search size={16} />
                <Input
                  type='text'
                  placeholder='Search'
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                />
              </SearchWrapper>
              <SortControls onClick={() => setIsSortOpen(!isSortOpen)}>
                <span>Filter</span>
                <Span isSortOpen={isSortOpen}>
                  <ChevronUp />
                </Span>
              </SortControls>
            </BlogNavigation>
            <CSSTransition in={isSortOpen} timeout={500}>
              {state => (
                <FilterContainer elOffsetHeight={offset} ref={ref} state={state}>
                  <FilterWrapper>
                    <ClearAll
                      type='button'
                      onClick={() => {
                        setFilterParms({
                          categories: [],
                          authors: []
                        })
                        setDirection(false)
                        setFilteredPosts([])
                      }}
                    >
                      Clear All
                    </ClearAll>
                  </FilterWrapper>
                  <FilterWrapper>
                    <FilterHeading>Category</FilterHeading>
                    <Categories>
                      {categories.map(el => {
                        const active = filterParams.categories.includes(el)
                        return (
                          el && (
                            <Chip active={active} key={el} onClick={e => handleFilterToggle(e, 'categories')}>
                              <span>{el}</span>
                              {active ? <CheckCircle size={16} /> : <XCircle size={16} />}
                            </Chip>
                          )
                        )
                      })}
                    </Categories>
                  </FilterWrapper>
                  <FilterWrapper>
                    <FilterHeading>Author</FilterHeading>
                    <Categories>
                      {authors.map(el => {
                        return (
                          el && (
                            <Chip
                              active={filterParams.authors.includes(el)}
                              key={el}
                              onClick={e => handleFilterToggle(e, 'authors')}
                            >
                              <span>{el}</span>
                              {filterParams.authors.includes(el) ? <CheckCircle size={16} /> : <XCircle size={16} />}
                            </Chip>
                          )
                        )
                      })}
                    </Categories>
                  </FilterWrapper>
                  <FilterWrapper>
                    <FilterHeading>Date</FilterHeading>
                    <Categories>
                      <Chip active={!direction} onClick={() => setDirection(false)}>
                        <span>Ascending</span>
                        {!direction ? <CheckCircle size={16} /> : <XCircle size={16} />}
                      </Chip>
                      <Chip active={direction} onClick={() => setDirection(true)}>
                        <span>Descending</span>
                        {direction ? <CheckCircle size={16} /> : <XCircle size={16} />}
                      </Chip>
                    </Categories>
                  </FilterWrapper>
                </FilterContainer>
              )}
            </CSSTransition>
            <CardWrapper>
              {Object.values(filterParams).every(x => isEmpty(x)) && !searchValue
                ? direction
                  ? allMdx.edges
                      .map(post => {
                        const { slug, author, title, tags, featureImg } = post.node.frontmatter
                        return (
                          <Card key={slug}>
                            <Link to={`/blog/${slug}/`}>
                              <ImgContainer>{getFluidOrSvg({ imgSrc: featureImg, alt: '' })}</ImgContainer>
                            </Link>
                            <CardBodyWrapper>
                              <CardBody>
                                <div>
                                  <Stroke />
                                  <Link to={`/blog/${slug}/`}>
                                    <Heading>{title}</Heading>
                                  </Link>
                                  <Author>{author}</Author>
                                </div>
                                <Category>{tags}</Category>
                              </CardBody>
                            </CardBodyWrapper>
                          </Card>
                        )
                      })
                      .reverse()
                  : allMdx.edges.map(post => {
                      const { slug, author, title, tags, featureImg } = post.node.frontmatter
                      return (
                        <Card key={slug}>
                          <Link to={`/blog/${slug}/`}>
                            <ImgContainer>{getFluidOrSvg({ imgSrc: featureImg, alt: '' })}</ImgContainer>
                          </Link>
                          <CardBodyWrapper>
                            <CardBody>
                              <div>
                                <Stroke />
                                <Link to={`/blog/${slug}/`}>
                                  <Heading>{title}</Heading>
                                </Link>
                                <Author>{author}</Author>
                              </div>
                              <Category>{tags}</Category>
                            </CardBody>
                          </CardBodyWrapper>
                        </Card>
                      )
                    })
                : direction
                ? filteredPosts
                    .map((post: any) => {
                      const { slug, author, title, tags, featureImg } = post.node.frontmatter
                      return (
                        <Card key={slug}>
                          <Link to={`/blog/${slug}/`}>
                            <ImgContainer>{getFluidOrSvg({ imgSrc: featureImg, alt: '' })}</ImgContainer>
                          </Link>
                          <CardBodyWrapper>
                            <CardBody>
                              <div>
                                <Stroke />
                                <Link to={`/blog/${slug}/`}>{title && <Heading>{title}</Heading>}</Link>
                                {author && <Author>{author}</Author>}
                              </div>
                              {tags && <Category>{tags}</Category>}
                            </CardBody>
                          </CardBodyWrapper>
                        </Card>
                      )
                    })
                    .reverse()
                : filteredPosts.map((post: any) => {
                    const { slug, author, title, tags, featureImg } = post.node.frontmatter
                    return (
                      <Card key={slug}>
                        <Link to={`/blog/${slug}/`}>
                          <ImgContainer>{getFluidOrSvg({ imgSrc: featureImg, alt: '' })}</ImgContainer>
                        </Link>
                        <CardBodyWrapper>
                          <CardBody>
                            <div>
                              <Stroke />
                              <Link to={`/blog/${slug}/`}>{title && <Heading>{title}</Heading>}</Link>
                              {author && <Author>{author}</Author>}
                            </div>
                            {tags && <Category>{tags}</Category>}
                          </CardBody>
                        </CardBodyWrapper>
                      </Card>
                    )
                  })}
            </CardWrapper>
            {/* <FetchMore>
              <Button
                bgColor="transparent"
                action="Fetch More Posts"
                btnHeight="2rem"
                onClick={() => console.log('hello')}
                secondary
              />
            </FetchMore> */}
            <BlogSignupWrapper>
              <DaiseeD file={D} alt='daisee logo' />
              <H2>Keep up to date with us</H2>
              <P>We&apos;re growing rapidly. Subscribe to stay in the loop.</P>
              <Formik
                initialValues={{ email: '' }}
                validationSchema={SignupSchema}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  const newValues = { ...values }
                  const formData = encode({ 'form-name': 'blog-signup', ...newValues })
                  try {
                    const res = await Axios.post('/', formData, {
                      headers: { 'content-type': 'application/x-www-form-urlencoded' }
                    })

                    await new Promise(resolve => setTimeout(resolve, 1500))

                    if (res.status === 200) {
                      actions.resetForm()
                      actions.setSubmitting(false)
                      setAlertMessage({
                        isOpen: true,
                        message: 'Thanks for subscribing',
                        alertType: AlertType.SUCCESS
                      })
                    }
                    actions.setSubmitting(false)
                  } catch (err) {
                    actions.resetForm()
                    setAlertMessage({
                      isOpen: true,
                      message: 'Oops, blog signup unsuccessful. Try again',
                      alertType: AlertType.ERROR
                    })
                    actions.setSubmitting(false)
                  }
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <StyledForm data-netlify name='blog-signup' netlify-honeypot='bot-field' method='POST'>
                    <input type='hidden' name='form-name' value='blog-signup' />
                    <input name='bot-field' type='hidden' />
                    <InputGroupWrapper>
                      <InputGroup>
                        <Label htmlFor='email'>Email</Label>
                        <StyledField id='email' type='email' name='email' errors={errors.email && touched.email} />
                        <StyledErrorMessage name='email' component='div' />
                      </InputGroup>
                      {isSubmitting ? (
                        <Button
                          action={<Oval width={14} height={14} />}
                          type='submit'
                          disabled
                          btnHeight='2.5rem'
                          aria-label='submit'
                          bgColor={themeContext.colors.green}
                        />
                      ) : (
                        <Button
                          action='subscribe'
                          type='submit'
                          disabled={isSubmitting}
                          btnHeight='2.5rem'
                          aria-label='disabled button'
                          bgColor={themeContext.colors.green}
                        />
                      )}
                    </InputGroupWrapper>
                  </StyledForm>
                )}
              </Formik>
            </BlogSignupWrapper>
          </BlogContainer>
        </BlogWrapper>
      </Main>
      <Footer />
    </Site>
  )
}

export const pageQuery = graphql`
  query AllBlogPosts {
    allMdx(filter: { frontmatter: { type: { eq: "post" } } }, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            slug
            date
            id
            type
            title
            author
            subtitle
            tags
            featureImg {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              extension
              publicURL
            }
            authorImg {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              extension
              publicURL
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`

const AuthorImg = styled.div`
  width: 96px;
  height: 96px;
  border: 2px solid ${({ theme }) => theme.colors.purple};
  border-radius: 50%;
  overflow: hidden;
  margin-top: 1.5rem;
  box-shadow: ${({ theme }) => theme.boxShadow.medium};
`

const AuthorReadingWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;
  color: #fff;
`

const StyledDate = styled.h4`
  font-weight: 500;
  font-size: 0.875rem;
`

const ReadingTime = styled.time`
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;
`

const SearchWrapper = styled.div`
  min-width: 30%;
  position: relative;

  svg {
    position: absolute;
    top: calc(50% - 8px);
    left: 1.25rem;
    z-index: 2;
    stroke: ${({ theme }) => theme.colors.grey700};
  }
`

const Input = styled.input`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 0.75rem;
  padding-left: 2.25rem;
  font-size: 0.875rem;
  margin: 0.5rem 0;
  border: none;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.grey200};
  width: 100%;
  transition: border 0.15s ease;
  position: relative;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.grey500};
  }
`

interface SpanProps {
  isSortOpen: boolean
}

const Span = styled.span<SpanProps>`
  transition: transform 0.5s ease;
  transform: ${({ isSortOpen }) => (isSortOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  line-height: 1;
`

interface FilterContainerProps {
  state: TransitionStatus
  elOffsetHeight: number
}

const handlePadding = (state: TransitionStatus, elOffsetHeight: number) => {
  switch (state) {
    case 'entering':
      return '0'
    case 'exited':
      return `-${elOffsetHeight}px`
    default:
      return ''
  }
}

const ClearAll = styled.button`
  border: none;
  outline: none;
  text-decoration: underline;
  background: transparent;
  font-weight: 500;
  cursor: pointer;
  grid-column: 2/2;
  color: ${({ theme }) => theme.colors.grey500};

  @media all and (max-width: 26.563em) {
    grid-column: 1/2;
  }
`

const FilterHeading = styled.p`
  line-height: 1;
  font-weight: 500;
`

const FilterContainer = styled.div<FilterContainerProps>`
  display: grid;
  gap: 3rem;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  margin-bottom: ${({ state, elOffsetHeight }) => handlePadding(state, elOffsetHeight)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 1.5rem 0;
`

interface ChipProps {
  active?: boolean
}

const Chip = styled.div<ChipProps>`
  padding: 0.5rem 1rem;
  transition: background 0.3s ease;
  background: ${({ theme, active }) => (active ? theme.colors.purple : theme.colors.grey500)};
  color: #fff;
  border-radius: 1.5rem;
  text-align: center;
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  user-select: none;
  text-transform: capitalize;

  @media all and (max-width: 48em) {
    font-size: 0.875rem;
  }

  span {
    line-height: 1;
    white-space: nowrap;
  }
`

const SortControls = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0.75rem;
  cursor: pointer;
  transition: opacity 0.3s ease;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 4px;
  padding: 0.75rem 12px;
  height: fit-content;

  &:hover {
    opacity: 0.5;
  }
`

const Categories = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: -0.75rem 0 0 -0.75rem;
  width: calc(100% + 0.75rem);
  > div {
    margin: 0.75rem 0 0 0.75rem;
  }
`

const FilterWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: 5rem auto;

  @media all and (max-width: 26.25em) {
    grid-auto-flow: row;
    grid-template-columns: auto;
  }
`

const H2 = styled.h2`
  text-align: center;
`

const P = styled.p`
  text-align: center;
`

const BG = styled.div`
  min-height: 60vh;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 3rem;
  background: ${({ theme }) =>
    `linear-gradient(101deg,${lighten(0.2, theme.colors.purple)} 0%, ${theme.colors.purple} 100%)`};
  display: flex;
  align-items: center;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};

  @media all and (max-width: 48em) {
    height: auto;
    padding: 0;
  }
`

const WindowInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  padding: 0 1.5rem;
  position: relative;

  @media all and (max-width: 48em) {
    grid-template-columns: 1fr;
    padding: 9rem 0 3rem;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DescriptionHeading = styled.h1`
  font-size: 2rem;
  padding: 1.5rem 0;
  line-height: 1.2;
  color: #fff;
  margin: 0;
`

const DescriptionParagraph = styled.p`
  max-width: 50ch;
  color: #fff;
  opacity: 0.9;

  padding-bottom: 1.5rem;
  line-height: 1.7;
`

const DescriptionAuthor = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  color: #fff;
  opacity: 0.9;
`

const BlogContainer = styled.section`
  margin-top: 4rem;
  overflow: hidden;
  position: relative;
`

const BlogWrapper = styled.div`
  margin-top: 6rem;
`

const BlogNavigation = styled.header`
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  position: relative;
  z-index: 3;
  background: #fff;
  align-items: start;
  flex-wrap: wrap;

  ${SearchWrapper} {
    padding: 0 0.5rem;
  }

  @media all and (max-width: 26.563em) {
    ${SearchWrapper} {
      padding: 1.5rem 0;
    }
  }
`

const BlogHeading = styled.h4`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.25rem;
  position: relative;
  white-space: nowrap;

  @media all and (max-width: 26.5625em) {
    font-size: 1rem;
  }

  &:after {
    content: '';
    position: absolute;
    width: 3rem;
    height: 4px;
    background: ${({ theme }) => theme.colors.grey900};
    bottom: 0;
    top: 2.875rem;
    left: 0;
  }
`

const CardWrapper = styled.article`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
  background: #fff;
  position: relative;
  z-index: 2;
`

const Author = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grey500};
`

const Stroke = styled.div`
  position: relative;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: auto;
  display: block;
  visibility: inherit;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 40px;
  height: 2px;
  background: ${({ theme }) => theme.colors.grey900};
  content: '';
  transition: width 0.5s ease, background 0.5s ease;
  will-change: width;
`

const Category = styled.p`
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: 600;
  transition: color 0.5s ease;
  color: #fff;
  opacity: 0.9;
  align-self: start;
`

const Card = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  &:hover ${Category} {
    color: ${({ theme }) => theme.colors.purple};
  }
  &:hover ${Stroke} {
    width: 80px;
    background: ${({ theme }) => theme.colors.purple};
  }
`

const ImgContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const WindowImgContainer = styled.div`
  position: relative;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.boxShadow.medium};
  border-radius: 16px;
  transition: box-shadow 0.25s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.large};
  }
`

const CardBodyWrapper = styled.div`
  position: relative;
  display: block;
  min-width: 0;
  max-width: none;
  width: 100%;
  height: auto;

  &:before {
    display: block;
    padding-top: 82.5%;
    content: '';

    @media all and (max-width: 48em) {
      padding-top: 62.5%;
    }
    @media all and (max-width: 20em) {
      padding-top: 82.5%;
    }
  }
`

const Heading = styled.h4`
  font-weight: 600;
  padding-bottom: 0.5rem;
`

const CardBody = styled.div`
  position: absolute;
  padding: 1.5rem;
  display: grid;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: auto;
  visibility: inherit;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  min-height: inherit;
  width: 100%;
  height: 100%;
`

const BlogSignupWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 6rem 3rem;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
  background: white;
`

const InputGroupWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
  align-items: center;

  @media all and (max-width: 26.25em) {
    grid-auto-flow: row;
  }

  button {
    margin-top: 0.55rem;
  }
`

export default BlogPage
